<template>
  <HeaderComponent />
  <div class="dashboard-body">
    <NewSidebar v-if="$store.state.userToken" />
    <div
      class="content"
      :class="
        !$store.state.isAuthenticated
          ? 'd-flex justify-content-center align-items-center'
          : ''
      "
    >
      <router-view />
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import HeaderComponent from "@/components/Header/HeaderComponent.vue";
import NewSidebar from "./components/NewSidebar/NewSidebar.vue";

export default {
  components: {
    HeaderComponent,
    NewSidebar,
  },
  data() {
    return {};
  },
  created() {},
};
</script>

<style>
@import "./assets/fonts/fonts.css";
:root {
  --gray-bg-color: #f9f9f9;
}
input:focus::placeholder {
  color: transparent !important;
}
body {
  direction: rtl;
  font-family: "globalFont", sans-serif;
  overflow-x: hidden;
  background-color: #f6f6f6;
}
.lg-hidden {
  display: none;
}
.input {
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  border-bottom: 1.5px solid #dee2e6;
  margin-bottom: 1rem;
  /* padding: 5px 0; */
  position: relative;
}
.input input {
  all: unset;
  width: 90%;
  padding: 10px 0;
  background-color: #fff;
}
li {
  list-style: none;
}
ul {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}
.textarea-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.upload-image {
  border: 1px solid #dee2e6;
  border-radius: 8px;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
}

.upload-image label {
  width: fit-content;
  background-color: #13323a;
  color: #fff;
  padding: 0.5rem;
  cursor: pointer;
}
/* Dark Mode */
body.dark {
  background-image: url("./assets/bg-body.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: #fff;
}
body.dark .input {
  border-color: #ffffff50;
}
body.dark .swal2-popup.swal2-modal {
  background-color: #1d4955;
  color: #fff;
}
.error-msg {
  background-color: #e74d3c39;
  border: 1px solid #e74c3c;
  padding: 10px 5px;
  margin: 10px 0;
  color: #e74c3c;
  border-radius: 8px !important;
  width: 100%;
}
.el-select__selected-item.el-select__placeholder {
  display: flex;
  color: #000;
}
body.dark .el-select__selected-item.el-select__placeholder {
  color: #fff;
}
body.dark .el-select__wrapper,
body.dark .el-input__wrapper {
  background-color: #1e1c1c !important;
}
body.dark .el-input--large .el-input__inner {
  color: #fff;
}
/* start search box */
.search-box {
  padding: 20px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-bottom: 2rem;
  border-radius: 8px;
}
body.dark .search-box {
  background-color: #111111;
}
.search-box select {
  outline: none !important;
  box-shadow: none !important;
}
body.dark .search-box input,
body.dark .search-box select {
  background-color: #1e1e1e;
  color: #fff;
}
body.dark .search-box select {
  border-color: #ffffff50;
}
body.dark .swal2-popup.swal2-modal {
  background-color: #111111;
}

.search-box .input {
  all: unset;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  display: flex;
  align-items: center;
  height: 100%;
}

.search-box .input input {
  padding: 0.5rem;
  width: calc(90% - 0.5rem);
}

.search-box .input button {
  all: unset;
  padding: 0.5rem;
  width: calc(10% - 0.5rem);
  height: 100%;
  text-align: center;
  background-color: #2ecc71;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.search-box .filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.search-box .filter .selects {
  width: calc(100% / 4);
}

.cancel-filter {
  all: unset;
  padding: 5px;
  width: 100%;
  text-align: center;
  color: #fff;
  background-color: #e74c3c;
  margin-top: 1rem;
  cursor: pointer;
  border-radius: 8px;
}
/* start dashboard body content */
.dashboard-body {
  /* height: calc(100vh - 5rem); */
  min-height: 100vh;
  display: flex;
  position: relative;
  /* background-image: url("./assets/Artboard 1@4x.png");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: repeat; */
}
.dashboard-body .content {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow-y: auto;
  padding: 1rem 0;
}
.dashboard-body .content::-webkit-scrollbar {
  width: 10px;
}
.dashboard-body .content::-webkit-scrollbar-thumb {
  background-color: #13323a;
}
.submit-btn {
  all: unset;
  background-color: #0099ff;
  color: #fff;
  text-align: center;
  padding: 0.8rem 0;
  border-radius: 0.5rem;
  cursor: pointer;
  display: block;
  width: 100%;
}
/* start switch btn */
.switch-btn .switch {
  padding: 0.25rem;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 50px;
  display: flex;
  width: 90px;
  height: 45px;
  cursor: pointer;
  justify-content: end;
  border: 1px solid #dee2e6;
}
.switch-btn .switch.off {
  justify-content: start;
  opacity: 0.6;
}
.switch-btn .switch.off > div {
  background-color: #dee2e6;
}
.switch-btn .switch.off > div svg {
  display: none;
}
.switch-btn .switch > div {
  width: calc(40px - 0.25rem);
  height: calc(40px - 0.25rem);
  border-radius: 50px;
  background-color: #13323a;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.switch-btn .switch > div svg {
  color: #fff;
  width: 1.5rem;
  height: 1.5rem;
}
.update-btn {
  all: unset;
  background-color: #f39c12;
  color: #fff;
  text-align: center;
  padding: 0.8rem 0;
  border-radius: 0.5rem;
  cursor: pointer;
  display: block;
  width: 100%;
}
.loading-placeholder {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff95;
  font-size: 1.5rem;
  font-weight: 700;
  z-index: 30;
}
.el-select-dropdown__item {
  color: #00000099 !important;
  font-size: 1.2rem !important;
  font-weight: 700 !important;
}
.el-select-dropdown__item.is-selected {
  background-color: #0099ff !important;
  color: #fff !important;
}
.el-select__placeholder {
  color: #00000099;
  font-size: 1.2rem;
  font-weight: 700;
}
.dp__pointer {
  color: #000000 !important;
  font-size: 1.1rem !important;
  font-weight: 700 !important;
}
.el-radio.is-bordered.el-radio--large {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 0.5rem !important;
}
.el-radio.is-bordered.el-radio--large .el-radio__label {
  font-size: 1.1rem !important;
  font-weight: 700 !important;
}
.swal2-image {
  width: 100% !important;
  height: 75% !important;
}
.swal2-popup.swal2-modal {
  overflow: hidden;
}
.el-textarea__inner {
  font-size: 1.1rem !important;
  font-weight: 700 !important;
  color: #000000 !important;
  resize: none !important;
  border-radius: 0.75rem !important;
  padding: 1rem !important;
  min-height: 150px !important;
}
.el-input__inner {
  font-size: 1.1rem !important;
  font-weight: 700 !important;
  color: #000000 !important;
  border-radius: 0.75rem !important;
}
.el-input__inner.el-input__inner__placeholder {
  color: #00000099 !important;
}
.el-radio__inner::after {
  width: 5px !important;
  height: 5px !important;
}
body.dark .el-input__inner {
  color: #ffffff !important;
}
body.dark .el-textarea__inner {
  color: #ffffff !important;
}
body.dark .el-textarea__inner {
  color: #ffffff !important;
}
body.dark .el-input__inner.el-input__inner__placeholder,
body.dark .el-select__placeholder,
body.dark .el-select-dropdown__item {
  color: #ffffff99 !important;
}
body.dark .el-select-dropdown__item.is-hovering {
  background-color: #1e1e1e;
}
body.dark .el-popper.is-light,
.el-popper.is-light > .el-popper__arrow:before {
  background: #111111;
  border: 1px solid #111111;
}
body.dark .el-popper.is-light,
body.dark .el-popper.is-light > .el-popper__arrow:before {
  background: #111111 !important;
  border: 1px solid #111111 !important;
  border-bottom-color: transparent !important;
  border-right-color: transparent !important;
}
body.dark .el-select-dropdown__wrap {
  background-color: #111111;
}
body.dark .el-select-dropdown__item {
  color: #fff;
}
body.dark .el-switch__core {
  background-color: #9d9e9f63;
}
body.dark .el-textarea__inner,
body.dark .table-body .table-row:not(.table-row.header-row) {
  background-color: #111111;
}
body.dark .table-body .table-row:not(.table-row.header-row):hover {
  background-color: #000000;
  transition: all 0.3s ease-in-out;
}
/* Media Queries */
@media (max-width: 1400px) {
  .container {
    width: calc(100% - 52px) !important;
  }
}
@media (max-width: 1200px) {
  .container {
    width: calc(100% - 52px) !important;
  }
}
@media (max-width: 992px) {
  .container {
    width: calc(100% - 52px) !important;
  }
}
@media (max-width: 768px) {
  .container {
    width: calc(100% - 52px) !important;
  }
  .switch-btn .switch {
    width: 70px;
    height: 35px;
  }
  .switch-btn .switch > div {
    width: calc(30px - 0.25rem);
    height: calc(30px - 0.25rem);
  }
}
@media (max-width: 576px) {
  .container {
    width: 100% !important;
  }
}
@media (max-width: 767px) {
  .lg-hidden {
    display: block;
  }
  .sm-hidden {
    display: none;
  }
}

.fancy_title {
  position: relative;
  font-weight: bold;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  color: #2c3e50;
  width: fit-content;
  font-size: 1.9rem;
}
body.dark .fancy_title {
  color: #ffffff;
}
.fancy_title :last-child {
  color: #ff7070;
}
.fancy_title::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  height: 5px;
  background-color: #bdc3c7;
  width: 40%;
  transition: all 0.5s ease;
}
.fancy_title:hover::after {
  width: 100%;
}
.el-radio.el-radio--large {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.el-radio__label {
  font-size: 1.1rem !important;
}
.el-card {
  border: none !important;
}
body.dark .el-card {
  background-color: #1e1e1e;
  color: #fff;
}
body.dark .el-card.is-always-shadow,
body.dark .el-card.is-hover-shadow:focus,
body.dark .el-card.is-hover-shadow:hover {
  box-shadow: 0 0 10px 0 #ffffff1a;
}
.question-container .header .el-button + .el-button {
  margin-left: 0;
}
.swal2-container {
  z-index: 100000000001 !important;
}
.add-questions .el-radio-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.add-questions .el-radio-group label {
  margin: 0 !important;
}
</style>
