<template>
  <header class="dashboard-header">
    <div class="container-fluid">
      <!-- <div class="user hidden-sm"></div> -->
      <div class="auth-btns-container" v-if="$store.state.isAuthenticated">
        <button @click="logout" class="logout-btn auth-btn desktop-only">
          <MiLogoutHalfCircle class="ms-2" />
          تسجيل خروج
        </button>
        <button
          @click="logout"
          class="logout-btn auth-btn text-center mobile-only"
        >
          <MiLogoutHalfCircle />
          خروج
        </button>
      </div>
      <div class="search-theme hidden-sm">
        <button class="dark-theme" @click="toggleDarkMode">
          <AnOutlinedMoon v-if="!this.darkMode" />
          <AnOutlinedSun v-else />
        </button>
      </div>
      <AnOutlinedMenu class="mobile-menu hidden-md open" @click="toggleMenu" />
      <AnOutlinedMenuUnfold
        class="mobile-menu hidden-md close"
        @click="toggleMenu"
      />
    </div>
  </header>
</template>

<script>
import {
  AnOutlinedMoon,
  AnOutlinedSun,
  AnOutlinedMenu,
  AnOutlinedMenuUnfold,
  MiLogoutHalfCircle,
} from "@kalimahapps/vue-icons";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

export default {
  name: "HeaderComponent",
  components: {
    AnOutlinedMoon,
    AnOutlinedSun,
    AnOutlinedMenu,
    AnOutlinedMenuUnfold,
    MiLogoutHalfCircle,
  },
  data() {
    return {
      darkMode: false,
      showLogout: false,
    };
  },
  methods: {
    toggleDarkMode() {
      this.darkMode = !this.darkMode;
      document.body.classList.toggle("dark");
      this.$store.commit("SET_DARK_MODE", this.darkMode);
    },
    toggleMenu() {
      const sidebar = document.querySelector(".sidebar-wrapper");
      sidebar?.classList?.toggle("active");
    },
    logout() {
      Swal.fire({
        title: " متاكد من تسجيل الخروج من المنصه ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#57F2AA",
        cancelButtonColor: "#e74c3c",
        confirmButtonText: "تسجيل خروج",
        cancelButtonText: " الغاء ",
      }).then((result) => {
        if (result.isConfirmed) {
          Cookies.remove("user_token");
          this.$store.commit("LOGOUT_USER");
          this.$router.push("/login");
          Swal.fire({
            toast: true,
            title: " تم تسجيل الخروج بنجاح ",
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            position: "top-end",
          });
        }
      });
    },
    stickHeader() {
      const header = document.querySelector("header.dashboard-header");
      if (window.scrollY > 0) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    },
  },
  mounted() {
    this.darkMode = localStorage.getItem("darkMode") === "true";
    if (this.darkMode) {
      document.body.classList.add("dark");
      this.$store.commit("SET_DARK_MODE", this.darkMode);
    }
  },
  created() {
    window.addEventListener("resize", () => {
      if (window.innerWidth > 768) {
        const sidebar = document.querySelector(".sidebar-wrapper");
        sidebar?.classList?.remove("active");
      }
    });
    window.addEventListener("scroll", this.stickHeader);
  },
  watch: {
    darkMode(val) {
      localStorage.setItem("darkMode", val);
    },
  },
};
</script>

<style scoped>
.auth-btn {
  all: unset;
  padding: 0.5rem 1rem;
  border-radius: 9.53px;
  border: 1.36px solid #1e1e1e;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
  width: max-content;
}
.logout-btn {
  background-color: #dad2c5;
  color: #1e1e1e;
}
.mobile-only {
  display: none;
}
@media (max-width: 767px) {
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: flex !important;
    align-items: center;
    padding: 0.5rem;
  }
}
/* start header */
header.dashboard-header {
  padding: 15px 0;
  background-color: #fbfbfbb2;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  max-height: 5rem;
}
header.dashboard-header.sticky {
  background-color: #fbfbfb;
  /* box-shadow: none; */
  position: sticky;
  top: 0;
  z-index: 10000000000;
}

header.dashboard-header > div {
  display: flex;
  align-items: center;
}

header.dashboard-header > div .user {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  overflow: hidden;
}

header.dashboard-header > div .logo {
  width: 84px;
}

header.dashboard-header > div .search-theme {
  width: 100%;
  margin: 0 15px;
  display: flex;
  align-items: center;
}

header.dashboard-header > div .search-theme .search-input {
  padding: 0 10px;
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #00000033;
  border-radius: 8px;
  overflow: hidden;
  margin-left: 10px;
}

header.dashboard-header > div .search-theme .search-input input,
header.dashboard-header > div .search-theme .search-input button {
  all: unset;
}

header.dashboard-header > div .search-theme .search-input input {
  width: 100%;
}

header.dashboard-header > div .search-theme .search-input button {
  padding: 8px;
  text-align: center;
  cursor: pointer;
}

header.dashboard-header > div .search-theme .search-input button.search-btn {
  border-right: 1px solid #00000033;
}

header.dashboard-header > div .search-theme .dark-theme {
  all: unset;
  padding: 8px 15px;
  text-align: center;
  border-radius: 8px;
  background-color: #d9d9d9;
  color: #000000;
  cursor: pointer;
  font-size: 18px;
}

header.dashboard-header .mobile-menu {
  cursor: pointer;
  font-size: 2rem;
}
.dashboard-sidebar {
  height: 100% !important;
  position: sticky;
  top: 1rem;
  right: 0;
}

.dashboard-mobile-menu-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  z-index: 50;
  transform: translateX(100%);
  transition: all 0.6s;
}

.dashboard-mobile-menu-container.open {
  transform: translateX(0);
}

.dashboard-mobile-menu-container .menu-header {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
}

.dashboard-mobile-menu-container .menu-header .close-menu {
  all: unset;
  padding: 10px;
  text-align: center;
  font-size: 22px;
  cursor: pointer;
}

.dashboard-mobile-menu-container .menu-header .dark-theme {
  all: unset;
  padding: 8px 15px;
  text-align: center;
  border-radius: 8px;
  background-color: #d9d9d9;
  color: #000000;
  cursor: pointer;
  font-size: 18px;
}

.dashboard-mobile-menu-container .menu-links {
  height: 90%;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.dashboard-mobile-menu-container .menu-links li {
  padding: 15px 0;
  margin-bottom: 10px;
  width: 100%;
  border-radius: 8px;
}

.dashboard-mobile-menu-container .menu-links li a {
  display: flex;
  padding: 0 10px;
}

.dashboard-mobile-menu-container .menu-links li a svg {
  margin-left: 15px;
}

/* Dark Mode */
body.dark .dashboard-header {
  background-color: #111111b2;
}
body.dark .search-bar input,
body.dark .dashboard-header .search-theme .search-input {
  border-color: #ffffff33 !important;
  background-color: #0d0d0d !important;
  color: #ffffff !important;
}
body.dark .dashboard-header .search-theme .search-input svg {
  color: #ffffff !important;
}
body.dark
  header.dashboard-header
  > div
  .search-theme
  .search-input
  button.search-btn {
  border-color: #ffffff33 !important;
}
body.dark header.dashboard-header > div .search-theme .dark-theme {
  background-color: #333333 !important;
  color: #fff !important;
}
.mobile-menu {
  display: none;
}
/* Media Queries */
@media (max-width: 768px) {
  .mobile-menu.close,
  :has(.sidebar-wrapper.active) .mobile-menu.open {
    display: none;
  }
  .mobile-menu.open,
  :has(.sidebar-wrapper.active) .mobile-menu.close {
    display: block;
  }
}
@media (max-width: 991px) {
  .header .container {
    max-width: 95%;
  }
  header.dashboard-header .container-fluid {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
