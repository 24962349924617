import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import Cookies from "js-cookie";
const routes = [
  {
    path: "/",
    name: "home",
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import("../views/Dashboard/DashboardView.vue"),
        meta: {
          title: "الرئيسية",
        },
      },
      // {
      //   path: "/courses-card-test",
      //   name: "courses-card-test",
      //   component: () => import("../components/CardDashboard/TestCard.vue"),
      //   meta: {
      //     title: "Test",
      //   },
      // },
      {
        path: "/new-create-collection",
        name: "new-create-collection",
        component: () => import("../views/CoursesCollection/NewCreateCollection.vue"),
        meta: {
          title: "إنشاء مجموعة كورسات",
        },
      },
      {

        path: "/confirm-collection",
        name: "confirm-collection",
        component: () => import("../views/CoursesCollection/ConfirmCollection.vue"),
        meta: {
          title: "إنشاء المجموعه",
        },
      },
      {

        path: "/update-collection",
        name: "update-collection",
        component: () => import("../views/CoursesCollection/UpdateCollection.vue"),
        meta: {
          title: "  تعديل على المجموعه",
        },
      },
      {

        path: "/list-collection",
        name: "list-collection",
        component: () => import("../views/CoursesCollection/ListCollection.vue"),
        meta: {
          title: "جميع المجموعات",
        },
      },


      {
        path: "/students",
        name: "students",
        component: () => import("../views/Students/StudentsView.vue"),
        meta: {
          title: "الطلاب",
        },
      },
      {
        path: "/students/find",
        name: "students-find",
        component: () => import("../views/Students/FindStudentView.vue"),
        meta: {
          title: "بحث ملف الطالب",
        },
      },
      {
        path: "/students/add",
        name: "students-add",
        component: () => import("../views/Students/AddNewStudentView.vue"),
        meta: {
          title: "إضافة طالب جديد",
        },
      },
      {
        path: "/students/:id",
        name: "student",
        props: true,
        component: () => import("../views/Student/StudentView.vue"),
        meta: {
          title: "الطالب",
        },
      },
      {
        path: "/News",
        name: "news-view",
        component: () => import("../views/News/NewsView.vue"),
        meta: {
          title: "أخر الأخبار",
        },
      },
      {
        path: "/News/create",
        name: "news-create",
        component: () => import("../views/News/CreateNews.vue"),
        meta: {
          title: "خبر جديد",
        },
      },
      {
        path: "/Analytics",
        name: "analytics-view",
        component: () => import("../views/Analytics/AnalyticsView.vue"),
        meta: {
          title: "تحليل البيانات",
        },
      },
      {
        path: "/courses",
        name: "courses-list",
        component: () => import("../views/Courses/CoursesList.vue"),
        meta: {
          title: "الكورسات",
        },
      },
      {
        path: "/student-in-course/:course_id/:course_name",
        name: "courses-student",
        props: true,
        component: () => import("../views/Courses/StudentInCourse.vue"),
        meta: {
          title: "الطلاب المشتركين في الكورس",
        },
      },
      {
        path: "/courses/create",
        name: "courses-create",
        component: () => import("../views/Courses/CreateCourse.vue"),
        meta: {
          title: "كورس جديد",
        },
      },
      {
        path: "/courses/update/:id",
        name: "courses-update",
        props: true,
        component: () => import("../views/Courses/CourseUpdate.vue"),
        meta: {
          title: " تعديل الكورس ",
        },
      },
      {
        path: "/courses/update",
        name: "courses-update-find",
        component: () => import("../views/Courses/ChooseUpdateCourseView.vue"),
        meta: {
          title: " تعديل الكورس ",
        },
      },
      {
        path: "/courses/categories",
        name: "courses-categories",
        component: () =>
          import("../views/Courses/Categories/CoursesCategories.vue"),
        meta: {
          title: "أقسام الكورسات",
        },
      },
      {
        path: "/courses/categories/create",
        name: "categories-create",
        component: () =>
          import("../views/Courses/Categories/CreateCategories.vue"),
        meta: {
          title: "قسم جديد",
        },
      },
      {
        path: "/courses/categories/update/:id",
        name: "categories-update",
        props: true,
        component: () =>
          import("../views/Courses/Categories/CategoriesUpdate.vue"),
        meta: {
          title: " تعديل القسم ",
        },
      },
      {
        path: "/units/list/:course_id",
        name: "units-list",
        props: true,
        component: () => import("../views/Units/UnitsView.vue"),
        meta: {
          title: "الوحدات",
        },
      },
      {
        path: "/units/all",
        name: "units-all",
        component: () => import("../views/Units/AllUnitsView.vue"),
        meta: {
          title: "جميع وحدات",
        },
      },
      {
        path: "/units/add",
        name: "units-add",
        props: true,
        component: () => import("../views/Units/FindUnitView.vue"),
        meta: {
          title: "إضافة وحدات",
        },
      },
      {
        path: "/file/list/:unit_id",
        name: "file-list",
        props: true,
        component: () => import("../views/Files/FilesListView.vue"),
        meta: {
          title: "جميع الفايلات",
        },
      },
      {
        path: "/file/add",
        name: "file-add",
        props: true,
        component: () => import("../views/Files/FileAddView.vue"),
        meta: {
          title: "إضافة الفايلات",
        },
      },
      {
        path: "/lessons/:unit_id/:course_id",
        name: "lessons",
        props: true,
        component: () => import("../views/Lessons/LessonsView.vue"),
        meta: {
          title: "الدروس",
        },
      },
      {
        path: "/lessons/update/:course_id/:lesson_id",
        name: "lesson-update",
        props: true,
        component: () => import("../views/Lessons/LessonUpdateView.vue"),
        meta: {
          title: "تعديل الدرس",
        },
      },
      {
        path: "/videos",
        name: "videos",
        component: () => import("../views/Videos/VideosView.vue"),
        meta: {
          title: "الفيديوهات",
        },
      },
      {
        path: "/students-point",
        name: "students_point",
        component: () => import("../views/Students/StudentsPointView.vue"),
        meta: {
          title: "نقاط الطلاب",
        },
      },

      // Category
      {
        path: "/category/add",
        name: "category-add",
        props: true,
        component: () => import("../views/Category/CategoryCreate.vue"),
        meta: {
          title: "إضافة فئات الأسئلة",
        },
      },
      {
        path: "/category/edit/:id",
        name: "category-edit",
        props: true,
        component: () => import("../views/Category/CategoryCreate.vue"),
        meta: {
          title: "تعديل فئات الأسئلة ",
        },
      },
      {
        path: "/category/view",
        name: "category-view",
        props: true,
        component: () => import("../views/Category/CategoryView.vue"),
        meta: {
          title: "جميع فئات الأسئلة",
        },
      },
      {
        path: "/exams",
        name: "exams",
        component: () => import("../views/Exams/ExamsView.vue"),
        meta: {
          title: "الامتحانات",
        },
      },
      {
        path: "/exams/:exam_id",
        name: "exam",
        props: true,
        component: () => import("../views/Exams/ExamDetailsView.vue"),
        meta: {
          title: "تفاصيل الامتحان",
        },
      },
      {
        path: "/add-exam",
        name: "add_exam",
        component: () => import("../views/Exams/AddExamView.vue"),
        meta: {
          title: "إضافة امتحان جديد",
        },
      },
      {
        path: "/update-exam/:exam_id",
        name: "update_exam",
        props: true,
        component: () => import("../views/Exams/UpdateExamView.vue"),
        meta: {
          title: "تعديل امتحان",
        },
      },
      {
        path: "/add-questions-exam/:exam_id/:unit?/:lesson?",
        name: "addQuestionsExam",
        props: true,
        component: () =>
          import("../views/AddQuestionsExam/AddQuestionsExamView.vue"),
        meta: {
          title: "اضافة أسئلة للامتحان",
        },
      },
      {
        path: "/add-questions-exam-bank/:course_id/:exam_id/:unit?/:lesson?/:exam_title",
        name: "addQuestionsExamBank",
        props: true,
        component: () =>
          import("../views/AddQuestionsExam/AddQuestionsExamBankView.vue"),
        meta: {
          title: "اضافة أسئلة للامتحان من البنك",
        },
      },
      {
        path: "/edit-question-exam/:exam_id/:question_id",
        name: "editQuestionExam",
        props: true,
        component: () =>
          import("../views/AddQuestionsExam/EditQuestionsExamView.vue"),
        meta: {
          title: "تعديل السؤال",
        },
      },
      {
        path: "/students-grades/:exam_id",
        name: "studentsGrades",
        props: true,
        component: () =>
          import("../views/StudentsGrades/StudentsGradesView.vue"),
        meta: {
          title: "درجات الطلاب",
        },
      },
      {
        path: "/students-grades/:exam_id/:student_id",
        name: "studentAnswers",
        props: true,
        component: () =>
          import("../views/StudentAnswers/StudentAnswersView.vue"),
        meta: {
          title: "درجات الطالب",
        },
      },
      {
        path: "/tasks",
        name: "tasks",
        component: () => import("../views/Tasks/TasksView.vue"),
        meta: {
          title: "الواجبات",
        },
      },
      {
        path: "/add-questions-task",
        name: "addQuestionsTask",
        component: () =>
          import("../views/AddQuestionsTask/AddQuestionsTaskView.vue"),
        meta: {
          title: "اضافة أسئلة للواجب",
        },
      },
      {
        path: "/trainings",
        name: "trainings",
        component: () => import("../views/Trainings/TrainingsView.vue"),
        meta: {
          title: "التدريبات",
        },
      },
      {
        path: "/add-questions-training",
        name: "addQuestionsTraining",
        component: () =>
          import("../views/AddQuestionsTraining/AddQuestionsTrainingView.vue"),
        meta: {
          title: "اضافة أسئلة للتدريب",
        },
      },
      {
        path: "/subscriptions-bills",
        name: "subscriptions_bills",
        component: () =>
          import("../views/SubscriptionsBills/SubscriptionsBillsView.vue"),
        meta: {
          title: "الفواتير والاشتراكات",
        },
      },
      {
        path: "/create-invoice",
        name: "invoice-create",
        component: () =>
          import("../views/SubscriptionsBills/CreateManualInvoiceView.vue"),
        meta: {
          title: "  إضافة فاتوره ",
        },
      },
      {
        path: "/subscriptions-courses",
        name: "subscriptions_courses",
        component: () =>
          import("../views/SubscriptionCourse/SubscriptionCourseView.vue"),
        meta: {
          title: " اشتراكات الكورسات",
        },
      },
      {
        path: "/cancel-subscriptions-courses",
        name: "subscriptions_cancel",
        component: () =>
          import("../views/SubscriptionCourse/CancelSubscription.vue"),
        meta: {
          title: " الغاء الاشتراك ",
        },
      },
      {
        path: "/coupons/student",
        name: "coupons",
        component: () => import("../views/Coupons/CouponsView.vue"),
        meta: {
          title: "الأكواد",
        },
      },
      {
        path: "/coupons/course",
        name: "coupons-course",
        component: () => import("../views/Coupons/CourseCodesView.vue"),
        meta: {
          title: "الأكواد",
        },
      },
      {
        path: "/previous-coupons",
        name: "previousCoupons",
        component: () =>
          import("../views/PreviousCoupons/PreviousCouponsView.vue"),
        meta: {
          title: "الأكواد",
        },
      },
      {
        path: "/users",
        name: "users",
        // component: () => import("../views/Users/UsersView.vue"),
        component: () => import("../views/Users/NewUsersView.vue"),
        meta: {
          title: "المستخدمين",
        },
      },
      {
        path: "/add-user",
        name: "addUser",
        component: () => import("../views/Users/AddUserView.vue"),
        meta: {
          title: "إضافة مستخدم",
        },
      },
      {
        path: "/edit-user/:username",
        name: "editUser",
        props: true,
        component: () => import("../views/Users/EditUserView.vue"),
        meta: {
          title: "تعديل مستخدم",
        },
      },
      // {
      //   path: "add-permission/:username",
      //   name: "addPermission",
      //   props: true,
      //   component: () => import("../views/Users/AddPermissionView-OLD.vue"),
      //   meta: {
      //     title: "إضافة صلاحية",
      //   },
      // },
      {
        path: "add-permission/:username",
        name: "addPermission",
        props: true,
        component: () => import("../views/Users/AddPermissionView.vue"),
        meta: {
          title: "إضافة صلاحية",
        },
      },
      {
        path: "/notifications",
        name: "notifications",
        component: () => import("../views/Notifications/NotificationsView.vue"),
        meta: {
          title: "التنبيهات (الاشعارات)",
        },
      },
      {
        path: "/notebooks",
        name: "notebooks",
        component: () => import("../views/Notebooks/NotebooksView.vue"),
        meta: {
          title: "المذكرات",
        },
      },
      {
        path: "/views",
        name: "views",
        component: () => import("../views/Views/ViewsList.vue"),
        meta: {
          title: "طلاب شاهدوا الدرس",
        },
      },
      {
        path: "/not-views/:lesson_id/:lesson",
        name: "notViews",
        props: true,
        component: () => import("../views/Views/NotViewsList.vue"),
        meta: {
          title: "طلاب لم يشاهدوا الدرس",
        },
      },
      {
        path: "/tested/:exam_id/:exam",
        name: "tested",
        props: true,
        component: () => import("../views/Views/TestedList.vue"),
        meta: {
          title: "طلاب دخلوا الامتحان",
        },
      },
      {
        path: "/not-tested/:exam_id/:exam",
        name: "notTested",
        props: true,
        component: () => import("../views/Views/NotTestedList.vue"),
        meta: {
          title: "طلاب لم يدخلوا الامتحان",
        },
      },
      {
        path: "/all_videos",
        name: "all_videos",
        component: () =>
          import("../views/VideosInPlateform/VideosInPlateformView.vue"),
        meta: {
          title: "جميع فيديوهات المنصه",
        },
      },
      {
        path: "/last-course-codes",
        name: "last_codes",
        component: () => import("../views/Coupons/LastCourseCodeView"),
        meta: {
          title: "الاكواد السابقه",
        },
      },
      {
        path: "/:pathMatch(.*)*",
        name: "notfound",
        component: () => import("@/views/NotFound.vue"),
        meta: {
          title: "صفحه غير موجوده",
          requiresAuth: false,
        },
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Auth/LoginView.vue"),
    meta: {
      title: "تسجيل الدخول",
      requiresAuth: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, form, next) => {
  // scroll to top
  window.scrollTo(0, 0);
  // handle reload for login
  const token = Cookies.get("user_token");
  if (token) {
    store.commit("LOGIN_USER", token);
  }

  const isAuthenticated = store.state.isAuthenticated;

  // to and from are both route objects. must call `next`.
  document.title = to.meta.title || "not found";

  // if user try to access login and register routes and user is auth
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isAuthenticated) {
      next();
    } else {
      next({ name: "login" });
    }
  } else {
    if (isAuthenticated) {
      next({ name: "home" });
    } else {
      next();
    }
  }
});

export default router;
