<template>
  <li :class="{ active: isActive }">
    <a
      v-if="hasSubItems"
      href="#"
      class="d-flex align-items-center justify-content-between"
      exact-active-class="active"
      @click="toggleSubMenu"
    >
      <div class="d-flex align-items-center item-sidebar w-100">
        <component :is="item?.icon" class="ms-2"></component>
        <span class="menu-text">{{ item.text }}</span>
      </div>
      <AkChevronDown
        v-if="hasSubItems && isSubMenuOpen"
        class="bi chevron-icon"
      />
      <AkChevronRight
        v-else-if="hasSubItems && !isSubMenuOpen"
        class="bi chevron-icon"
      />
    </a>
    <router-link
      v-else
      :to="{ name: item.link_name }"
      class="d-flex align-items-center justify-content-between"
      exact-active-class="active"
      @click="closeSidebar"
    >
      <div class="d-flex align-items-center item-sidebar w-100">
        <component :is="item?.icon" class="ms-2"></component>
        <span class="menu-text">{{ item.text }}</span>
      </div>
      <AkChevronDown
        v-if="hasSubItems && isSubMenuOpen"
        class="bi chevron-icon"
      />
      <AkChevronRight
        v-else-if="hasSubItems && !isSubMenuOpen"
        class="bi chevron-icon"
      />
    </router-link>
    <transition
      name="submenu"
      @enter="(el) => (el.style.height = el.scrollHeight + 'px')"
      @leave="(el) => (el.style.height = '0')"
    >
      <ul v-if="hasSubItems && isSubMenuOpen" class="submenu list-unstyled">
        <li v-for="subItem in item.subItems" :key="subItem">
          <router-link
            :to="{ name: subItem.name }"
            class="d-flex align-items-center item-sidebar w-100"
            exact-active-class="active"
            @click="closeSidebar"
          >
            <component :is="subItem?.icon" class="ms-2"></component>
            <span class="menu-text">{{ subItem.display_name }}</span>
          </router-link>
        </li>
      </ul>
    </transition>
  </li>
</template>
<script>
import { AkChevronDown, AkChevronRight } from "@kalimahapps/vue-icons";
export default {
  name: "SidebarItem",
  components: {
    AkChevronDown,
    AkChevronRight,
  },
  props: ["isActive", "item", "closeSidebar"],
  data() {
    return {
      isSubMenuOpen: false,
    };
  },
  computed: {
    hasSubItems() {
      return this.item.subItems && this.item.subItems.length > 0;
    },
  },
  methods: {
    toggleSubMenu() {
      if (this.hasSubItems) {
        this.isSubMenuOpen = !this.isSubMenuOpen;
      }
    },
  },
};
</script>

<style scoped>
li a {
  padding: 15px 20px;
  display: block;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

li a svg:not(.chevron-icon) {
  font-size: 1.2rem !important;
  margin-right: 10px;
  transition: transform 0.3s ease;
  margin: 0 auto !important;
}
.sidebar-wrapper.active .submenu li a svg:not(.chevron-icon),
.sidebar-wrapper.expanded li a svg:not(.chevron-icon) {
  margin: 0 !important;
  margin-left: 10px !important;
}
.sidebar-wrapper li a svg.chevron-icon {
  display: none;
}
.sidebar-wrapper.expanded li a svg.chevron-icon {
  display: block;
}
.item-sidebar {
  margin: 0 auto;
}
.sidebar-wrapper.expanded .item-sidebar {
  margin: 0;
}

li a .menu-text,
li a .chevron-icon {
  opacity: 0;
  transform: translateX(-10px);
  transition: all 0.3s ease;
  font-size: 0;
  font-weight: bold;
}

li a .chevron-icon {
  transition: transform 0.3s ease;
}

li a:hover {
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
}

li a:hover svg:not(.chevron-icon) {
  transform: scale(1.1);
}

a.active {
  background: #16a085;
  color: #fff;
}

.submenu {
  overflow: hidden;
  height: 0;
  transition: height 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background: rgba(0, 0, 0, 0.1);
}

.submenu li a {
  padding: 10px 20px 10px 56px;
  font-size: 1rem;
}

.submenu li a svg {
  font-size: 1.25rem !important;
  margin: 0 auto !important;
}
.sidebar-wrapper.active .submenu li a svg,
.sidebar-wrapper.expanded .submenu li a svg {
  font-size: 1.25rem !important;
  margin: 0 !important;
  margin-left: 10px !important;
}
.sidebar-wrapper.expanded li a svg {
  margin-left: 10px !important;
}
.sidebar-wrapper.expanded .menu-text,
.sidebar-wrapper.expanded li a svg.chevron-icon {
  opacity: 1 !important;
  font-size: 1rem !important;
  transform: translateX(0) !important;
}
.sidebar-wrapper.active li a svg:not(.chevron-icon),
.sidebar-wrapper.expanded li a svg:not(.chevron-icon) {
  margin: 0 !important;
  margin-left: 10px !important;
}
.submenu li a {
  padding: 1rem 1.25rem;
}
.sidebar-wrapper.expanded .submenu li a {
  padding: 1rem 1.25rem 1rem 0rem;
}
.submenu .menu-text {
  font-size: 0;
  font-weight: bold;
}

@media (max-width: 768px) {
  li a .menu-text {
    opacity: 1;
    transform: translateX(-10px);
    transition: all 0.3s ease;
    font-size: 1.2rem !important;
  }
  .submenu li a .menu-text {
    font-size: 1rem !important;
  }
}
</style>
