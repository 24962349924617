import { createStore } from "vuex";

const localStoragePlugin = (store) => {
  const savedSelectedCourses = localStorage.getItem("selectedCourses");
  if (savedSelectedCourses) {
    store.commit("INITIALIZE_SELECTED_COURSES", JSON.parse(savedSelectedCourses));
  }

  const savedCollData = localStorage.getItem("collData");
  if (savedCollData) {
    store.commit("INITIALIZE_COLL_DATA", JSON.parse(savedCollData));
  }

  store.subscribe((mutation, state) => {
    if (
      ["INITIALIZE_SELECTED_COURSES", "SET_SELECTED_COURSES", "ADD_SELECTED_COURSE", "REMOVE_SELECTED_COURSE"].includes(mutation.type)
    ) {
      localStorage.setItem("selectedCourses", JSON.stringify(state.selectedCourses));
    }
    if (
      ["INITIALIZE_COLL_DATA", "SET_COLL_DATA"].includes(mutation.type)
    ) {
      localStorage.setItem("collData", JSON.stringify(state.collData));
    }
  });
};

export default createStore({
  state: {
    isAuthenticated: false,
    userToken: null,
    setDarkMode: false,
    selectedCourses: [],
    collData: {},
  },
  getters: {
    isDarkMode: (state) => state.setDarkMode,
    selectedCourses: (state) => state.selectedCourses,
    collData: (state) => state.collData,
  },
  mutations: {
    INITIALIZE_SELECTED_COURSES(state, courses) {
      state.selectedCourses = courses;
    },
    SET_SELECTED_COURSES(state, courses) {
      state.selectedCourses = courses;
    },
    ADD_SELECTED_COURSE(state, course) {
      if (!state.selectedCourses.some((c) => c.course_id === course.course_id)) {
        state.selectedCourses.push(course);
      }
    },
    REMOVE_SELECTED_COURSE(state, courseId) {
      state.selectedCourses = state.selectedCourses.filter(
        (c) => c.course_id !== courseId
      );
    },
    LOGIN_USER(state, token) {
      state.isAuthenticated = true;
      state.userToken = token;
    },
    LOGOUT_USER(state) {
      state.isAuthenticated = false;
      state.userToken = null;
    },
    SET_DARK_MODE(state, value) {
      state.setDarkMode = value;
    },
    INITIALIZE_COLL_DATA(state, data) {
      state.collData = data;
    },
    SET_COLL_DATA(state, data) {
      state.collData = data;
    },
  },
  actions: {
    storeSelectedCourses({ commit }, courses) {
      commit("SET_SELECTED_COURSES", courses);
    },
    storeCollData({ commit }, data) {
      commit("SET_COLL_DATA", data);
    },
  },
  plugins: [localStoragePlugin],
  modules: {},
});
