<template>
  <div class="sidebar-wrapper" :class="{ expanded: isExpanded }">
    <nav id="sidebar" class="bg-dark text-white">
      <div class="sidebar-header">
        <button
          @click="toggleSidebar"
          class="btn btn-link text-white toggle-btn"
        >
          <BsList v-if="!isExpanded" class="bi" />
          <BsX v-else class="bi" />
        </button>
      </div>

      <ul class="list-unstyled components">
        <SidebarItem
          v-for="(item, index) in menuItems"
          :key="index"
          :item="item"
          :closeSidebar="closeSidebar"
        />
      </ul>
    </nav>
  </div>
</template>
<script>
import SidebarItem from "./SidebarItem.vue";
import {
  BsHouseDoor,
  HiNewspaper,
  BsGraphUp,
  BsPersonFill,
  BsPerson,
  BsPersonPlus,
  BsAward,
  BsFilm,
  BsPeopleFill,
  BsPeople,
  BsPersonCircle,
  BsTable,
  BsJournalText,
  BsJournalPlus,
  BsJournalCode,
  TaNewSection,
  AkNewspaper,
  BsQrCodeScan,
  BsCreditCard2Back,
  BsPatchQuestion,
  PhExam,
  BsDatabaseAdd,
  BxCategoryAlt,
  CaCategoryNew,
  BsCardHeading,
  HeOutlineBills,
  MdOutlinedSubscriptions,
  FaFileInvoiceDollar,
  MdOutlinedUnsubscribe,
  BsPersonVideo3,
  AnOutlinedFundView,
  BsFileEarmarkPlus,
  BsList,
  BsX,
  BsJournals,
  BsJournal,
  LuPackage2,
  // LuPackageCheck,
  // LuPackageMinus,
  LuPackagePlus,
  LuPackageOpen,
} from "@kalimahapps/vue-icons";

export default {
  name: "NewSidebar",
  components: {
    SidebarItem,
    BsList,
    BsX,
    // BsShare,
  },
  data() {
    return {
      isExpanded: false,
      menuItems: [
        {
          icon: BsHouseDoor,
          text: "الرئيسي",
          link_name: "dashboard",
          // subItems: ['Analytics', 'Reports', 'Real-time']
        },

        {
          icon: BsPeopleFill,
          text: "الطلاب",
          subItems: [
            {
              icon: BsPeople,
              name: "students",
              display_name: "جميع الطلاب ",
            },
            {
              icon: BsPersonCircle,
              name: "students-find",
              display_name: "ملف الطالب",
            },
            {
              icon: BsPersonPlus,
              name: "students-add",
              display_name: "إضافة طالب جديد",
            },
            {
              icon: BsAward,
              display_name: "نقاط الطلاب",
              name: "students_point",
              // subItems: ['Analytics', 'Reports', 'Real-time']
            },
          ],
        },
        {
          icon: BsTable,
          text: "الكورسات",
          subItems: [
            {
              icon: BsJournalText,
              name: "courses-list",
              display_name: "جميع الكورسات",
            },
            {
              icon: BsJournalPlus,
              name: "courses-create",
              display_name: "إضافة كورس جديد",
            },
            {
              icon: BsJournalCode,
              name: "courses-update-find",
              display_name: "تعديل كورس",
            },
            {
              icon: BsJournals,
              name: "courses-categories",
              display_name: "أقسام الكورسات",
            },
          ],
        },
        {
          icon: LuPackage2,
          text: " مجموعات الكورسات",
          subItems: [
            {
              icon: LuPackagePlus,
              name: "new-create-collection",
              display_name: "إنشاء مجموعة كورسات",
            },

            {
              icon: LuPackageOpen,
              name: "list-collection",
              display_name: "جميع مجموعات الكورسات",
            },
          ],
        },

        {
          icon: BsGraphUp,
          text: "الأحصائيات",
          subItems: [
            {
              icon: BsGraphUp,
              name: "analytics-view",
              display_name: "الأحصائيات",
            },
          ],
        },
        {
          icon: BsJournal,
          text: "الوحدات",
          subItems: [
            {
              icon: BsJournalText,
              name: "units-all",
              display_name: "جميع وحدات",
            },
            {
              icon: BsJournalPlus,
              name: "units-add",
              display_name: "إضافة وحدات",
            },
            {
              icon: BsFileEarmarkPlus,
              name: "file-add",
              display_name: "إضافة فايلات",
            },
          ],
        },
        {
          icon: BsQrCodeScan,
          text: "الأكواد",
          subItems: [
            // {
            //   icon: "bi-credit-card-2-front",
            //   name: "coupons",
            //   display_name: "كوبونات الخصم",
            // },
            {
              icon: BsCreditCard2Back,
              name: "coupons",
              display_name: " أكواد الطلاب ",
            },
            {
              icon: BsCreditCard2Back,
              name: "coupons-course",
              display_name: " أكواد الكورسات ",
            },
          ],
        },
        {
          icon: BsPatchQuestion,
          text: "الامتحانات",
          subItems: [
            {
              icon: PhExam,
              name: "exams",
              display_name: " الامتحانات ",
            },
            {
              icon: BsDatabaseAdd,
              name: "add_exam",
              display_name: " امتحان جديد ",
            },
            {
              icon: BxCategoryAlt,
              name: "category-view",
              display_name: " فئات الأسئلة ",
            },
            {
              icon: CaCategoryNew,
              name: "category-add",
              display_name: " إضافة فئه أسئلة جديدة ",
            },
          ],
        },
        {
          icon: BsCardHeading,
          text: "الاشتراكات والفواتير",
          subItems: [
            {
              icon: HeOutlineBills,
              name: "subscriptions_bills",
              display_name: " الفواتير  ",
            },
            {
              icon: MdOutlinedSubscriptions,
              name: "subscriptions_courses",
              display_name: " الاشتراكات ",
            },
            {
              icon: FaFileInvoiceDollar,
              name: "invoice-create",
              display_name: " اشتراك يدوي ",
            },
            {
              icon: MdOutlinedUnsubscribe,
              name: "subscriptions_cancel",
              display_name: " الغاء اشتراك ",
            },
          ],
        },
        {
          icon: BsPersonVideo3,
          text: " المشاهدات ",
          subItems: [
            {
              icon: AnOutlinedFundView,
              name: "views",
              display_name: " جميع المشاهدات  ",
            },
            {
              icon: BsFilm,
              display_name: "احصائيات الفيديوهات",
              name: "all_videos",
              // subItems: ['Analytics', 'Reports', 'Real-time']},
            },
          ],
        },
        {
          icon: BsPersonFill,
          text: "المستخدمين",
          subItems: [
            {
              icon: BsPerson,
              name: "users",
              display_name: "جميع المستخدمين",
            },
            {
              icon: BsPersonPlus,
              name: "addUser",
              display_name: "إضافة مستخدم جديد",
            },
          ],
        },
        {
          icon: HiNewspaper,
          text: "أخر الأخبار",
          subItems: [
            {
              icon: AkNewspaper,
              name: "news-view",
              display_name: "جميع الأخبار",
            },
            {
              icon: TaNewSection,
              name: "news-create",
              display_name: "إضافة خبر جديد",
            },
          ],
        },

        // {
        //     icon: 'bi-gear',
        //     text: 'Settings',
        //     subItems: ['General', 'Security', 'Notifications']
        // }
      ],
    };
  },
  computed: {},
  methods: {
    toggleSidebar() {
      this.isExpanded = !this.isExpanded;
    },
    closeSidebar() {
      if (window.innerWidth > 768) {
        this.isExpanded = false;
      } else {
        const sidebar = document.querySelector(".sidebar-wrapper");
        sidebar?.classList?.remove("active");
      }
    },
    checkWidth() {
      if (window.innerWidth < 768) {
        this.isExpanded = false;
      }
    },
    stickySidebar() {
      const sidebar = document.querySelector(
        ".sidebar-wrapper.active" || ".sidebar-wrapper.expanded"
      );
      if (window.scrollY > 0) {
        sidebar?.classList?.add("sticky");
      } else {
        sidebar?.classList?.remove("sticky");
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.checkWidth);
    window.addEventListener("scroll", this.stickySidebar);
  },
};
</script>
<style scoped>
.sidebar-wrapper {
  position: sticky;
  top: 73px;
  height: 100%;
  min-height: 100vh;
  z-index: 100000000;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateX(0);
}

.sidebar-wrapper #sidebar {
  min-width: 80px;
  max-width: 80px;
  height: 100%;
  min-height: 100vh;
  position: relative;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42);
  background: linear-gradient(180deg, #3dc1a2 0%, #3dc1a2 100%);
}

body.dark .sidebar-wrapper #sidebar {
  /* background: linear-gradient(180deg, #14162b 0%, #13152c 100%); */
}

.sidebar-wrapper #sidebar .sidebar-header {
  padding: 20px;
  text-align: center;
}

.sidebar-wrapper #sidebar .sidebar-header .toggle-btn {
  padding: 0;
  /* font-size: 1.5rem; */
  width: fit-content;
  height: 100%;
  transform: rotate(0);
  transition: all 0.3s ease-in-out;
}

.sidebar-wrapper #sidebar .sidebar-header .toggle-btn:hover {
  color: #16a085 !important;
  transform: rotate(180deg);
}

.toggle-btn svg {
  line-height: normal;
  font-size: 1.5rem;
}

/* .sidebar-wrapper #sidebar .sidebar-header .toggle-btn svg {
  transition: transform 0.3s ease;
} */
.sidebar-wrapper #sidebar .components {
  padding: 20px 0;
  height: calc(100vh - 140px);
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar-wrapper #sidebar .components::-webkit-scrollbar {
  width: 5px;
}

.sidebar-wrapper #sidebar .components::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
}

.sidebar-wrapper #sidebar .sidebar-footer {
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  padding: 20px;
  background: rgba(0, 0, 0, 0.1);
}

.sidebar-wrapper #sidebar .sidebar-footer a {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  transition: all 0.3s ease;
}

.sidebar-wrapper #sidebar .sidebar-footer a:hover {
  color: #fff;
  transform: translateX(5px);
}

.sidebar-wrapper #sidebar .sidebar-footer a svg {
  font-size: 1.2rem;
  margin-right: 10px;
}

.sidebar-wrapper.expanded #sidebar {
  min-width: 250px;
  max-width: 250px;
}

.sidebar-wrapper:not(.expanded) .toggle-btn:hover {
  transform: rotate(-180deg);
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.sidebar-wrapper {
  animation: slideIn 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

@media screen and (max-width: 768px) {
  .sidebar-wrapper {
    position: fixed;
    top: 73px;
    transform: translate(100%);
  }

  .sidebar-wrapper.expanded,
  .sidebar-wrapper.active {
    transform: translate(0);
  }

  .sidebar-wrapper.expanded.sticky,
  .sidebar-wrapper.active.sticky {
    position: fixed;
    top: 0;
    transition: all 0 ease-in-out;
    z-index: 99999999999;
  }

  .sidebar-wrapper.active #sidebar {
    min-width: 250px;
    max-width: 250px;
  }

  .sidebar-wrapper .sidebar-header {
    display: none;
  }
}
</style>
